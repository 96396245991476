
import './jquery';
import 'jquery-nice-select';
import './jquery.waypoints.min';
import './jquery.countup.min';
import tippy from 'tippy.js';

window.$(document).ready(function () {                
    $('select').niceSelect();
    $('.counter').countUp();


    $('.review').each(function(){

        if($(this).data().template) {

            new tippy(this, {
                allowHTML : true,
                theme : 'light-border',
                placement : 'right-start',
                content : $('#' + $(this).data().template)[0].innerHTML,
                animation: 'shift-toward',
                arrow: false,
            })

        } 
    })

});

function initFreshChat() {
    window.fcWidget.init({
        token: "07169033-1374-4fc0-81ff-d503a417558a",
        host: "https://wchat.freshchat.com"
    });
}

function initialize(i, t) {
    var e;
    i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src =
        "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
}

function initiateCall() {
    initialize(document, "freshchat-js-sdk")
}
window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window.attachEvent("load",
    initiateCall, !1);